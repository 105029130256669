import { fetchFuelTypes } from "../services/cars-service"

const initialState = {
	loading: false,
	error: false,
	fetched: false,
	data: []
}

const FETCH_FUEL_TYPES = "FETCH_FUEL_TYPES"

export const fetchFuelTypesAction = () => ({
	type: FETCH_FUEL_TYPES,
	payload: fetchFuelTypes()
})

export default (state = initialState, action) => {
	switch (action.type) {
		case FETCH_FUEL_TYPES + "_PENDING":
			return { ...initialState, loading: true }
		case FETCH_FUEL_TYPES + "_REJECTED":
			return { ...initialState, error: true }
		case FETCH_FUEL_TYPES + "_FULFILLED":
			return { ...initialState, fetched: true, data: action.payload.data }
		default:
			return state
	}
};
