import { fetchInsuranceAgencies } from "../services/cars-service"

const initialState = {
	loading: false,
	error: false,
	fetched: false,
	data: []
}

const FETCH_INSURANCE_AGENCIES = "FETCH_INSURANCE_AGENCIES"

export const fetchInsuranceAgenciesAction = () => ({
	type: FETCH_INSURANCE_AGENCIES,
	payload: fetchInsuranceAgencies()
})

export default (state = initialState, action) => {
	switch (action.type) {
		case FETCH_INSURANCE_AGENCIES + "_PENDING":
			return { ...initialState, loading: true }
		case FETCH_INSURANCE_AGENCIES + "_REJECTED":
			return { ...initialState, error: true }
		case FETCH_INSURANCE_AGENCIES + "_FULFILLED":
			return { ...initialState, fetched: true, data: action.payload.data }
		default:
			return state
	}
};
