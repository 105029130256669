import { fetchCarModels } from "../services/cars-service"

const initialState = {
  loading: false,
  error: false,
  fetched: false,
  data: []
}

const FETCH_MODELS = "FETCH_MODELS"

export const fetchModelsAction = (brandId) => ({
  type: FETCH_MODELS,
  payload: fetchCarModels(brandId)
})

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_MODELS + "_PENDING":
      return { ...initialState, loading: true }
    case FETCH_MODELS + "_REJECTED":
      return { ...initialState, error: true }
    case FETCH_MODELS + "_FULFILLED":
      return { ...initialState, fetched: true, data: action.payload.data }
    default:
      return state
  }
};
