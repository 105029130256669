import { fetchGenders } from "../services/cars-service"

const initialState = {
	loading: false,
	error: false,
	fetched: false,
	data: []
}

const FETCH_GENDERS = "FETCH_GENDERS"

export const fetchGendersAction = () => ({
	type: FETCH_GENDERS,
	payload: fetchGenders()
})

export default (state = initialState, action) => {
	switch (action.type) {
		case FETCH_GENDERS + "_PENDING":
			return { ...initialState, loading: true }
		case FETCH_GENDERS + "_REJECTED":
			return { ...initialState, error: true }
		case FETCH_GENDERS + "_FULFILLED":
			return { ...initialState, fetched: true, data: action.payload.data }
		default:
			return state
	}
};
