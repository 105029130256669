import { combineReducers } from "redux"
import brands from "./brands"
import models from "./models"
import fuelTypes from "./fuelTypes"
import transmissionTypes from "./transmissionTypes"
import form from "./form"
import amount from "./amount"
import insuranceAgencies from "./insuranceAgencies"
import martialStatus from "./martialStatus"
import yesOrNo from "./yesOrNo"
import genders from "./genders"
import carTypes from "./carTypes"
import countries from "./countries"
import timeLeft from "./timeLeft"
import setPagesAreFilled from "./setPagesAreFilled"
import meetingHours from "./meetingHours"
import showPopup from "./showPopup"
import cities from "./cities"

export default combineReducers({
	brands,
	models,
	carTypes,
	fuelTypes,
	transmissionTypes,
	countries,
	form,
	amount,
	insuranceAgencies,
	martialStatus,
	yesOrNo,
	genders,
	timeLeft,
	setPagesAreFilled,
	meetingHours,
	showPopup,
	cities,
})
