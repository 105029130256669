const initialState = {
	brand: null,
	brandLabel: null,
	model: null,
	modelLabel: null,
	carType: null,
	carTypeLabel: null,
	eurotaxCarId: null,
	productionYear: null,
	productionYearLabel: null,
	minProductionYear: null,
	maxProductionYear: null,
	fuelType: null,
	fuelTypeLabel: null,
	isLPGLabel: null,
	transmissionType: null,
	transmissionTypeLabel: null,
		// second substep
	numberOfSeats: null,
	engineCapacity: null,
	immobilizer: null,
	immobilizerLabel: null,
	transmissionLock: null,
	transmissionLockLabel: null,
	vehicleOrigin: null,
	vehicleOriginLabel: null,
	alarm: null,
	alarmLabel: null,
		// third substep
	vehiclePurchaseDate: null,
	isCoOwner: null,
	icCoOwnerLabel: null,
	isTechCheckValid: null,
	isTechCheckValidLabel: null,
	firstRegistrationDate: null,
	vehicleMileage: null,
	estimatedMileage: null,
	zipCode: null,
	city: null,
	// second step
	isOCInsurance: null,
	isOCInsuranceLabel: "nie",
	isACInsurance: null,
	isACInsuranceLabel: "nie",
	isNNWInsurance: null,
	isNNWInsuranceLabel: "nie",
	insuranceBeginningDate: null,
	// third step
	insuranceAgencyOC: null,
	insuranceAgencyOCLabel: null,
	yearsOCWasBought: null,
	OCDamageAmount: null,
	insuranceAgencyAC: null,
	insuranceAgencyACLabel: null,
	yearsACWasBought: null,
	ACDamageAmount: null,
	// fourth step
	gender: null,
	genderLabel: null,
	birthDate: null,
	getLicenseDate: null,
	martialStatus: null,
	martialStatusLabel: null,
	profession: null,
	haveChildren: null,
	haveChildrenLabel: null,
	contactType: 1,
	phoneNumber: null,
	emailAddress: null,
	meetingHour: null,
	meetingHourLabel: null,
	dataProcessingAccepted: null,
}

const SET_FORM_VALUE = "SET_FORM_VALUE"

export const setFormValueAction = (name, value) => ({
  type: SET_FORM_VALUE,
  payload: { name, value }
})

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_FORM_VALUE:
      return { ...state, [action.payload.name]: action.payload.value }
    default:
      return state
  }
};
