const initialState = {
	firstStepFirstTabFilled: false,
	firstStepSecondTabFilled: false,
	firstStepThirdTabFilled: false,
	secondStepFilled: false,
	thirdStepFilled: false,
	fourthStepFilled: false,
}

const SET_FIRST_STEP_FIRST_TAB_FILLED = "SET_FIRST_STEP_FIRST_TAB_FILLED";
const SET_FIRST_STEP_SECOND_TAB_FILLED = "SET_FIRST_STEP_SECOND_TAB_FILLED";
const SET_FIRST_STEP_THIRD_TAB_FILLED = "SET_FIRST_STEP_THIRD_TAB_FILLED";
const SET_SECOND_STEP_FILLED = "SECOND_STEP_FILLED";
const SET_THIRD_STEP_FILLED = "THIRD_STEP_FILLED";
const SET_FOURTH_STEP_FILLED = "FOURTH_STEP_FILLED";

export const setFirstStepFirstTabFilled = firstStepFirstTabFilled => ({
	type: SET_FIRST_STEP_FIRST_TAB_FILLED, firstStepFirstTabFilled,
})
export const setFirstStepSecondTabFilled = firstStepSecondTabFilled => ({
	type: SET_FIRST_STEP_SECOND_TAB_FILLED, firstStepSecondTabFilled,
})
export const setFirstStepThirdTabFilled = firstStepThirdTabFilled => ({
	type: SET_FIRST_STEP_THIRD_TAB_FILLED, firstStepThirdTabFilled,
})
export const setSecondStepFilled = secondStepFilled => ({
	type: SET_SECOND_STEP_FILLED, secondStepFilled,
})
export const setThirdStepFilled = thirdStepFilled => ({
	type: SET_THIRD_STEP_FILLED, thirdStepFilled,
})
export const setFourthStepFilled = fourthStepFilled => ({
	type: SET_FOURTH_STEP_FILLED, fourthStepFilled,
})

export default (state = initialState, action) => {
	switch (action.type) {
		case SET_FIRST_STEP_FIRST_TAB_FILLED:
			return {...state, firstStepFirstTabFilled: action.firstStepFirstTabFilled}
		case SET_FIRST_STEP_SECOND_TAB_FILLED:
			return {...state, firstStepSecondTabFilled: action.firstStepSecondTabFilled}
		case SET_FIRST_STEP_THIRD_TAB_FILLED:
			return {...state, firstStepThirdTabFilled: action.firstStepThirdTabFilled}
		case SET_SECOND_STEP_FILLED:
			return {...state, secondStepFilled: action.secondStepFilled}
		case SET_THIRD_STEP_FILLED:
			return {...state, thirdStepFilled: action.thirdStepFilled}
		case SET_FOURTH_STEP_FILLED:
			return {...state, fourthStepFilled: action.fourthStepFilled}
		default:
			return state
	}
};
