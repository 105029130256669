import { fetchYesOrNo } from "../services/cars-service"

const initialState = {
	loading: false,
	error: false,
	fetched: false,
	data: []
}

const FETCH_YES_OR_NO = "FETCH_YES_OR_NO"

export const fetchYesOrNoAction = () => ({
	type: FETCH_YES_OR_NO,
	payload: fetchYesOrNo()
})

export default (state = initialState, action) => {
	switch (action.type) {
		case FETCH_YES_OR_NO + "_PENDING":
			return { ...initialState, loading: true }
		case FETCH_YES_OR_NO + "_REJECTED":
			return { ...initialState, error: true }
		case FETCH_YES_OR_NO + "_FULFILLED":
			return { ...initialState, fetched: true, data: action.payload.data }
		default:
			return state
	}
};
