import axios from "axios"

export const fetchCarBrands = () => axios.get(process.env.API_URL + "/car-brands")
export const fetchCarModels = (brandId) => axios.get(process.env.API_URL + "/car-models/" + brandId)
export const fetchCarTypes = (modelId) => axios.get(process.env.API_URL + "/car-types/" + modelId)
export const fetchFuelTypes = () => axios.get(process.env.API_URL + "/fuel-type")
export const fetchTransmissionTypes = () => axios.get(process.env.API_URL + "/transmission-type")
export const fetchCountries = () => axios.get(process.env.API_URL + "/countries");
export const fetchSeatsNumber = () => axios.get(process.env.API_URL + "/seats-number")
export const fetchYears = () => axios.get(process.env.API_URL + "/production-year")
export const fetchAmount = () => axios.get(process.env.API_URL + "/amount")
export const fetchInsuranceAgencies = () => axios.get(process.env.API_URL + "/insurance-agencies")
export const fetchMartialStatus = () => axios.get(process.env.API_URL + "/martial-status")
export const fetchYesOrNo = () => axios.get(process.env.API_URL + "/yes-or-no")
export const fetchGenders = () => axios.get(process.env.API_URL + "/genders")
export const fetchMeetingHours = () => axios.get(process.env.API_URL + "/meeting-hour")
export const fetchCities = (cityId) => axios.get(process.env.API_URL + "/cities/" + cityId)
export const sendEmail = (data) => axios.post(process.env.SEND_EMAIL_URL, data);