import React, { useState, useMemo, useCallback } from "react";
import styled from "styled-components"
import Lottie from "react-lottie";

const AnimationWrapper = styled.div`
	margin-left: 0;
	height: 203px;	
	@media(min-width: 1024px) {
		margin-left: 0;
		height: 156px;
		@media(min-height: 768px) {
			height: 225px;
			margin-left: -20px;
		}
		@media(min-height: 900px) {
			margin-left: -30px;
		}
		@media(min-height: 1024px) {
			margin-left: -50px;
			height: 280px;
		}
	}
	@media(min-width: 1280px) {
		height: 156px;
		margin-left: -10px;
		@media(min-height: 768px) {
			height: 225px;
			margin-left: -40px;
		}
		@media(min-height: 900px) {
			margin-left: -30px;
		}
		@media(min-height: 1024px) {
			margin-left: -50px;
			height: 205px;
		}
	}
	@media(min-width: 1920px) {
		margin-left: 0;
		@media(min-height: 768px) {
			height: 210px;
			margin-left: -10px;
		}
		@media(min-height: 900px) {
			margin-left: 0;
			height: 180px;
		}
		@media(min-height: 950px) {
			margin-left: 0;
			height: 210px;
		}
		@media(min-height: 1024px) {
			height: 320px;
			margin-left: -50px;
		}
	}
	div {	
		@media(min-width: 1024px) {
			width: calc(100% - 40px) !important;
			@media(min-height: 768px){
				width: calc(100% + 20px) !important;
			}
			@media(min-height: 900px) {
				width: calc(100% + 30px) !important;
			}
			@media(min-height: 1024px) {
				width: calc(100% + 50px) !important;
			}		
		}
		@media(min-width: 1280px) {
			width: calc(100% + 10px) !important;
			@media(min-height: 768px){
				width: calc(100% + 40px) !important;
			}
			@media(min-height: 900px) {
				width: calc(100% + 30px) !important;
			}
			@media(min-height: 1024px) {
				width: calc(100% + 50px) !important;
			}	
		}
		@media(min-width: 1920px) {
			width: calc(100% - 75px) !important;
			@media(min-height: 768px){
				width: calc(100% - 10px) !important;
			}
			@media(min-height: 900px) {
				width: calc(100% - 35px) !important;
			}
			@media(min-height: 950px) {
				width: calc(100% + 0px) !important;
			}
			@media(min-height: 1024px) {
				width: calc(100% + 50px) !important;
			}
		}
	}
`;

const SingleAnimationComponent = ({animationJson}) => {

	const defaultOptions = {
		loop: false,
		autoplay: true,
		animationData: animationJson,
		rendererSettings: {
			preserveAspectRatio: "xMidYMid slice"
		}
	};

	const [isPaused, setIsPaused] = useState(false);
	const [isStopped, setIsStopped] = useState(false);

	const handlePlay = useCallback(() => {
		setIsStopped(false);
		setIsPaused(false);
	}, []);

	const handleStop = useCallback(() => {
		setIsStopped(true);
	}, []);

	const handlePause = useCallback(() => {
		setIsPaused(!isPaused);
	}, [isPaused]);

	return useMemo(() => {
		return (
			<AnimationWrapper className="controlled">
				<Lottie
					options={defaultOptions}
					isStopped={isStopped}
					isPaused={isPaused}
				/>
			</AnimationWrapper>
		);
	}, [isStopped, isPaused, handleStop, handlePlay, handlePause]);
}

export default SingleAnimationComponent;
