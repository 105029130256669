import { fetchCountries } from "../services/cars-service"

const initialState = {
	loading: false,
	error: false,
	fetched: false,
	data: []
}

const FETCH_COUNTRIES = "FETCH_COUNTRIES"

export const fetchCountriesAction = () => ({
	type: FETCH_COUNTRIES,
	payload: fetchCountries()
})

export default (state = initialState, action) => {
	switch (action.type) {
		case FETCH_COUNTRIES + "_PENDING":
			return { ...initialState, loading: true }
		case FETCH_COUNTRIES + "_REJECTED":
			return { ...initialState, error: true }
		case FETCH_COUNTRIES + "_FULFILLED":
			return { ...initialState, fetched: true, data: action.payload.data }
		default:
			return state
	}
};
