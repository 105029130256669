import { fetchTransmissionTypes } from "../services/cars-service"

const initialState = {
	loading: false,
	error: false,
	fetched: false,
	data: []
}

const FETCH_TRANSMISSION_TYPES = "FETCH_TRANSMISSION_TYPES"

export const fetchTransmissionTypesAction = () => ({
	type: FETCH_TRANSMISSION_TYPES,
	payload: fetchTransmissionTypes()
})

export default (state = initialState, action) => {
	switch (action.type) {
		case FETCH_TRANSMISSION_TYPES + "_PENDING":
			return { ...initialState, loading: true }
		case FETCH_TRANSMISSION_TYPES + "_REJECTED":
			return { ...initialState, error: true }
		case FETCH_TRANSMISSION_TYPES + "_FULFILLED":
			return { ...initialState, fetched: true, data: action.payload.data }
		default:
			return state
	}
};
