import { fetchCarBrands } from "../services/cars-service"

const initialState = {
  loading: false,
  error: false,
  fetched: false,
  data: []
}

const FETCH_BRANDS = "FETCH_BRANDS"

export const fetchBrandsAction = () => ({
  type: FETCH_BRANDS,
  payload: fetchCarBrands()
})

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_BRANDS + "_PENDING":
      return { ...initialState, loading: true }
    case FETCH_BRANDS + "_REJECTED":
      return { ...initialState, error: true }
    case FETCH_BRANDS + "_FULFILLED":
      return { ...initialState, fetched: true, data: action.payload.data }
    default:
      return state
  }
};
