import React from "react"
import styled, {createGlobalStyle, ThemeProvider} from "styled-components"
import {theme} from "../utils/theme"
import SideStepsPreview from "../components/sideStepsPreview/SideStepsPreview";
import { TransitionProvider, TransitionViews } from "gatsby-plugin-transitions";
import PolisonetLogo from "../images/SideStepsPreview/polisonet-logo-bottom.svg";
import {connect} from "react-redux";
import Helmet from "react-helmet";

const GlobalStyle = createGlobalStyle`
html {
		overflow-x: hidden;
	}
	body {
		width: 100%;
		padding: 0;
		margin: 0;
		overflow-x: hidden;
		font-family: ${({theme}) => theme.fontFamily.mainFont}; 
		background-color: ${({theme}) => theme.colors.formLightGreen};
		-webkit-tap-highlight-color: transparent;
		@media(min-width: 1024px) {
			background-color: ${({theme}) => theme.colors.formWhite};
		}
	}
	
	button {
		font-family: ${({theme}) => theme.fontFamily.mainFont};
		&:focus {
			outline: none;
		}
	}
	
	a {
		&:focus {
		outline: none;
		}
	}

	*, *::before, *::after{
		box-sizing: border-box;
	}
	
	*::placeholder {
		font-family: ${({theme}) => theme.fontFamily.mainFont};
	}
	
	h1, h2, h3, h4, h5, h6 {
		margin: 0; 
		padding: 0;
	}
	
	p {
		margin: 0;
		padding: 0;
	}
	
	ul {
		list-style-type: none;
		padding: 0;
		margin: 0;
	}
`;

const StyledWrapper = styled.div`
	margin-top: 90px;
	border-radius: 40px;
	background-color: white;
	z-index: 101;
	position: ${({isPopupShown}) => isPopupShown ? "fixed" : "unset"};
	div.views {
		div.view-container {
			div.view {
				div.tl-edges {
					overflow-x: ${({isPopupShown}) => isPopupShown ? "visible" : "hidden"};
				}
			}
		}
	}
	&.tl-edges {
		background-color: blue;
	}
	@media(min-width: 768px) {
		margin-top: 125px;
	}
	@media(min-width: 1024px) {
		margin-top: 0;
		margin-left: 340px;
		border-radius: 0;
	}
	@media(min-width: 1280px) {
		margin-left: 340px;
	}
	@media(min-width: 1920px) {
		margin-left: 405px;
	}
	div {
		div {
			box-shadow: none!important;
		}
	} 
`;

const InfoWrapper = styled.div`
	position: ${({isPopupShown}) => isPopupShown ? "unset" : "fixed"};;
	bottom: 0;
	z-index: 100;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 100%;
	height: 55px;
	padding: 8px 20px 10px;
	background-color: ${({theme}) => theme.colors.formLightGreen};
	@media(min-width: 1024px) {
		display: none;
	}
`;

const InfoText = styled.p`
	font-size: 14px;
	font-weight: ${({theme}) => theme.fontWeight.regular};
	color: ${({theme}) => theme.colors.formDarkGreen};
	text-align: left;
	&.align-right {
		text-align: right;
	}
	span {
		font-weight: ${({theme}) => theme.fontWeight.black};
		line-height: 18px;
	}
`;

const BottomLogoWrapper = styled.a`
	display: flex;
	flex-direction: column;
	justify-content: center;
	height: 100%; 
`;

const LogoImage = styled.img`
	height: 14px;
`

const Layout = (props) => {
	const {children, path, location, timeLeft, isPopupShown} = props;

	return (
		<ThemeProvider theme={theme}>
			<Helmet>
				<script type="text/javascript">
					{`(function () {var script = document.createElement('script');script.src = 'https://cdnjs.cloudflare.com/ajax/libs/babel-polyfill/6.26.0/polyfill.min.js';script.async = false;document.head.appendChild(script);}())</script><script>var __cp = {"id":(function(){ if (location.pathname == 'https:\\/\\/www.polisonet.pl\\/kalkulator\\/') {return "2lArJZa2P7dp7kmRsLzxp9TDsK4RR3QDPGFqXfYc5qA";} else {return "lVKRoUggNJ16ZhXn8L4Avo4wk3g0MglVZZfz3x6aOKc";}}()),"version":"1.1"};(function (window, document) {var cp = document.createElement('script');cp.type = 'text/javascript';cp.async = false;cp.src = "++cdn-widget.callpage.io+build+js+callpage.js".replace(/[+]/g, '/').replace(/[=]/g, '.');var s = document.getElementsByTagName('script')[0];s.parentNode.insertBefore(cp, s);if (window.callpage) {alert('You could have only 1 CallPage code on your website!');} else {window.callpage = function (method) {if (method == '__getQueue') {return this.methods;}else if (method) {if (typeof window.callpage.execute === 'function') {return window.callpage.execute.apply(this, arguments);}else {(this.methods = this.methods || []).push({arguments: arguments});}}};window.callpage.__cp = __cp;window.callpage('api.button.autoshow');}})(window, document);`}
				</script>
			</Helmet>
			<>
				<GlobalStyle/>
				<SideStepsPreview path={path}/>
				<StyledWrapper isPopupShown={isPopupShown}>
					<TransitionProvider location={location}
					                    mode="successive"
					                    enter={{
						                    opacity: 0,
						                    transform: "translate3d(60vw,0,0)",
						                    config: {
							                    mass: 1.5,
							                    tension: 100,
							                    friction: 20,
							                    clamp: false
						                    },
						                    onRest: () => {
						                    }
					                    }}
					                    usual={{
						                    opacity: 1,
						                    transform: "translate3d(0vh,0vh,0)"
					                    }}
					                    leave={{
						                    opacity: 0,
						                    transform: "translate3d(-5vw,0vh,0)",
						                    config: {
							                    duration: 100
						                    }
					                    }}
					                    config={{
					                    	duration: 0
					                    }}
					                    >
						<TransitionViews>
						{children}
						</TransitionViews>
					</TransitionProvider>
				</StyledWrapper>
				<InfoWrapper isPopupShown={isPopupShown}>
					<InfoText>kalkulacja:<br/><span>252nddsf</span></InfoText>
					<BottomLogoWrapper target="_blank" href={"https://www.polisonet.pl"}>
						<LogoImage src={PolisonetLogo}/>
					</BottomLogoWrapper>
					<InfoText className="align-right">pozostało:<br/><span>{timeLeft}</span></InfoText>
				</InfoWrapper>
			</>
		</ThemeProvider>
	)
};

export default connect(state => ({
	timeLeft: state.timeLeft.time,
	isPopupShown: state.showPopup.isPopupShown
}), null)(Layout)