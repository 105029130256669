import React from "react"
import styled from "styled-components"
import Logo from "../../images/SideStepsPreview/polisonet-logo.png"
import Tick from "../../images/SideStepsPreview/tick-img.svg"
import TickMobile from "../../images/SideStepsPreview/tick-mobile.svg"
import Clouds from "../../images/SideStepsPreview/clouds.svg"
import posed from "react-pose"
import { changeTimeLeft } from "../../state/timeLeft"
import {connect, useDispatch} from "react-redux";
import {Link} from "gatsby";
import FirstStepFirstTabAnimation from "../../animations/marka-model-typ";
import FirstStepSecondTabAnimation from "../../animations/dane-techniczne";
import FirstStepThirdTabAnimation from "../../animations/dane-zakupu";
import SecondStepAnimation from "../../animations/ubezpieczenia";
import ThirdStepAnimation from "../../animations/historia-ubezpieczenia";
import FourthStepAnimation from "../../animations/wlasciciel";
import SingleAnimationComponent from "../menuAnimations/SingleAnimationComponent";

const ModuleWrapper = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	z-index: 100;
	width: 100vw;
	height: 82px;
	background-color: ${({theme}) => theme.colors.formLightGreen};
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	padding: 0 10px;
	@media(min-width: 375px) {
		padding: 0 20px;
	}
	@media(min-width: 768px) {
		height: 125px;
	}
	@media(min-width: 1024px) {
		width: 340px;
		height: 100vh;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		padding: 0 20px 15px;
		background-color: ${({theme}) => theme.colors.formWhite};
	}
	@media(min-width: 1920px) {
		width: 405px;
	}
`;

const PosedImage = posed.img({
	enter: {
		opacity: 1,
		x: 0,
		y: 0,
		scale: 1,
		delay: 150,
		transition: {
			ease: 'linear',
			default: {duration: 250},
		},
	},
	exit: {
		opacity: 0,
		x: "-100%",
		y: 0,
		scale: .5,
		transition: {duration: 250},

	},
});

const LogoContainer = styled.div`
	display: none;
	flex-direction: column;
	justify-content: center;
	margin: 0 auto;
	width: 100%;
	height: 160px;
	@media(min-width: 1024px) {
		display: flex;
		height: 116px;
	}
	@media(min-width: 1280px) {
	  height: 116px;
	  @media(min-height: 900px) {
	  	height: 160px;
	  }
	}
`;

const LogoWrapper = styled.div`
	width: 72px;
	height: 60px;
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	background-image: url( ${({bgImg}) => bgImg});
	@media(min-width: 1024px) {
		width: 86px;
		height: 70px;
		margin: 0 auto;
	}
	@media(min-width: 1280px) {
		@media(min-height: 900px) {
			width: 111px;
			height: 90px;
		}
	}
`;

const MobileStepPreviewWrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 100%;
	min-height: 82px;
	@media(min-width: 1024px) {
		display: none;
	}
`;

const MobileCurrentStepWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	height: 100%;
`

const TabletLogoContainer = styled.div`
	display: none;
	flex-direction: row;
	justify-content: center;
	width: 100px;
	height: 68px;
	margin-bottom: 30px;
	position: absolute;
	left: calc(50% - 50px);
	top: 25px;
	@media(min-width: 768px) {
		display: flex;
	}
`;

const TabletLogoWrapper = styled.div`
	width: 99px;
	height: 80px;
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	background-image: url( ${({bgImg}) => bgImg});
`;

const ProgressBarWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	height: 100%;
`
const ProgressBar = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	height: 20px;
	@media(min-width: 375px) {
		height: 30px;
	}
`;

const SmallDotWrapper = styled(Link)`
	display: flex;
	flex-direction: column;
	justify-content: center;
	height: 100%;
	pointer-events: ${({isLinkActive}) => isLinkActive ? "initial" : "none"};
	text-decoration: none;
`

const ProgressSmallDots = styled.div`
	width: 11px;
	height: 11px;
	border-radius: 50%;
	border: 1px solid ${({theme}) => theme.colors.formBorderGreen};
	background-color: ${({theme}) => theme.colors.formTransparent};
	&.done {
		background-color: ${({theme}) => theme.colors.formDarkOrange};
		border: 1px solid ${({theme}) => theme.colors.formDarkOrange};
	}
`;

const ProgressLineWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	height: 100%;
`

const ProgressLine = styled.div`
	height: 1px;
	background-color: ${({theme}) => theme.colors.formBorderGreen};
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 8px;
	@media(min-width: 400px) {
		width: 12px;
	}
	&.wider {
		width: 14px; 
		@media(min-width: 400px) {
			width: 20px;
		}
	}
	&.done {
		background-color: ${({theme}) => theme.colors.formDarkOrange};
	}
`

const StepsAndBottomImageWrapper = styled.div`
	background-color:  ${({theme}) => theme.colors.formLightGreen};
	border-radius: 30px;
	height: calc(100% - 116px);
	padding: 25px 15px 15px;
	display: none;
	flex-direction: column;
	justify-content: space-between;
	overflow-x: hidden;
	overflow-y: hidden;
	@media(min-width: 1024px) {
		display: flex;
	}
	@media(min-width: 1280px) {
	  height: calc(100% - 116px);
	  padding: 25px 35px 55px;
	  @media(min-height: 900px) {
	  	height: calc(100% - 160px);
	  }
	}
`;

const CloudsAndStepsWrapper = styled.div`
	display: none;
	flex-direction: column;
	justify-content: flex-start;
	@media(min-width: 1024px) {
		display: flex;
	}
`

const CloudsWrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	width: 100%;
	margin-left: 30px;
	margin-bottom: 30px;
	@media(min-width: 1280px) {
		margin-left: 10px;
	}
	@media(min-width: 1920px) {
		margin-bottom: 50px;
	}
`;

const CloudsImage = styled.img`
	height: 38px;
`

const StepsWrapper = styled.div`
	display: none;
	flex-direction: column;
	justify-content: space-between;
	margin: 0 auto;
	@media(min-width: 1024px) {
		display: flex;
	}
`;

const MainStep = styled(Link)`
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	margin-bottom: 20px;
	pointer-events: ${({isLinkActive}) => isLinkActive ? "initial" : "none"};
	text-decoration: none;
	@media(min-width: 1280px) {
		@media(min-height: 900px) {
			margin-bottom: 40px;
		}
	}
`;

const MainStepNumber = styled.div`
	width: 20px;
	height: 20px;
	border: 1px solid ${({theme}) => theme.colors.formBorderGreen};
	border-radius: 50%;
	background-color: ${({theme}) => theme.colors.formTransparent};
	font-family: ${({theme}) => theme.fontFamily.numberFont};
	font-size: 8px;
	line-height: 11px;
	font-weight: ${({theme}) => theme.fontWeight.extraBold};
	display: flex;
	justify-content: center;
	color: ${({theme}) => theme.colors.formDarkGreen};
	pointer-events: ${({isLinkActive}) => isLinkActive ? "initial" : "none"};
	text-decoration: none;
	@media(min-width: 375px) {
		width: 30px;
		height: 30px;
		font-size: 14px;
		line-height: 19px;
	}
	&.filled {
		border: none;
		background-color: ${({theme}) => theme.colors.formDarkOrange};
	}
	&.active {
		border: 1px solid ${({theme}) => theme.colors.formDarkOrange};
		color: ${({theme}) => theme.colors.formDarkOrange};
	}
	@media(min-width: 1024px) {
		padding-top: 2px;
		width: 30px;
		height: 30px;
		font-size: 13px;
		line-height: 18px;
		@media(min-height: 900px) {
			width: 35px;
			height: 35px;
			font-size: 14px;
			line-height: 19px;
		}
	}
`;

const MainStepNumberMobile = styled(Link)`
	width: 20px;
	height: 20px;
	border: 1px solid ${({theme}) => theme.colors.formBorderGreen};
	border-radius: 50%;
	background-color: ${({theme}) => theme.colors.formTransparent};
	font-family: ${({theme}) => theme.fontFamily.numberFont};
	font-size: 8px;
	line-height: 11px;
	font-weight: ${({theme}) => theme.fontWeight.extraBold};
	display: flex;
	justify-content: center;
	color: ${({theme}) => theme.colors.formDarkGreen};
	pointer-events: ${({isLinkActive}) => isLinkActive ? "initial" : "none"};
	text-decoration: none;
	@media(min-width: 375px) {
		width: 30px;
		height: 30px;
		font-size: 14px;
		line-height: 19px;
	}
	&.filled {
		border: none;
		background-color: ${({theme}) => theme.colors.formDarkOrange};
	}
	&.active {
		border: 1px solid ${({theme}) => theme.colors.formDarkOrange};
		color: ${({theme}) => theme.colors.formDarkOrange};
	}
	@media(min-width: 1024px) {
		font-size: 15px;
		line-height: 18px;
		padding-top: 6px;
		width: 36px;
		height: 36px;
	}
	@media(min-width: 1920px) {
		font-size: 16px;
		line-height: 22px;
		width: 40px;
		height: 40px;
		padding-top: 7px;
	}
`;

const NumberSpan = styled.span`
	display: block;
	margin-top: 3px;
	@media(min-width: 375px) {
		margin-top: 4px;
	}
	@media(min-width: 768px) {
		margin-top: 4px;
	}
	@media(min-width: 1024px) {
		margin-top: 3px;
		@media(min-height: 900px) {
			margin-top: 5px;
		}
	}
	&.filled {
		display: none;
	}
`;

const TickImg = styled.img`
	width: 8px;
	margin: 0 auto;
	@media(min-width: 375px) {
		width: 11px;
	}
	@media(min-width: 1024px) {
		width: 12px;
		padding-bottom: 2px;
		@media(min-height: 900px) {
			width: 15px;
		}
	}
`

const MainStepName = styled.p`
	color: ${({theme}) => theme.colors.formDarkGreen};
	font-weight: ${({theme}) => theme.fontWeight.black};
	display: flex;
	flex-direction: column;
	justify-content: center;
	text-transform: uppercase;
	font-size: 14px;
	line-height: 18px;
	text-decoration: none;
	&.filled {
		border: none;
		color: ${({theme}) => theme.colors.formStepDoneGreen};
	}
	&.active {
		color: ${({theme}) => theme.colors.formDarkOrange};
	}
	@media(min-width: 1024px) {
		font-size: 14px;
		line-height: 18px;
		margin-left: 15px;
		@media(min-height: 900px) {
			font-size: 16px;
			line-height: 20px;
		}
	}
`;

const SubStepsWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	width: 100%;
	margin: 0 0 15px 48px;
	@media(min-width: 1280px) {
		@media(min-height: 900px) {
			margin-top: -15px;
		}
	}	
`;

const SubStepName = styled(Link)`
	font-size: 14px;
	font-weight: ${({theme}) => theme.fontWeight.semiBold};
	line-height: 18px;
	color: ${({theme}) => theme.colors.formDarkGreen};
	text-decoration: none;
	pointer-events: ${({isLinkActive}) => isLinkActive ? "initial" : "none"};
	&.disabled-link {
  		pointer-events: none;	
	}
	&.filled {
		color: ${({theme}) => theme.colors.formStepDoneGreen};
	}
	&.active {
		color: ${({theme}) => theme.colors.formDarkOrange};
	}	
	@media(min-width: 1024px) {
		font-size: 14px;
		line-height: 18px;
		margin-bottom: 12px;
		@media(min-height: 900px) {
			font-size: 16px;
			line-height: 20px;
			margin-bottom: 20px;
		}
	}
`;

const BottomImagesWrapper = styled.div`
	display: none;
	flex-direction: row;
	justify-content: center;
	position: relative;
	width: 100%;
	margin: 0 auto;
	@media(min-width: 1024px) {
		display: flex;
	}
`

const SideStepsPreview = (props) => {
	const {path, firstStepFirstTabFilled, firstStepSecondTabFilled, firstStepThirdTabFilled, secondStepFilled, thirdStepFilled, fourthStepFilled } = props;
	let firstStepFirstTabActive;
	let firstStepSecondTabActive;
	let firstStepThirdTabActive;
	let SecondStepFirstTab;
	let ThirdStepFirstTab;
	let FourthStepFirstTab;
	let currentStep;
	const dispatch = useDispatch()

	switch (path) {
		case "/":
			firstStepFirstTabActive = true;
			firstStepSecondTabActive = false;
			firstStepThirdTabActive = false;
			SecondStepFirstTab = false;
			ThirdStepFirstTab = false;
			FourthStepFirstTab = false;
			dispatch(changeTimeLeft("około 150 s"));
			currentStep = 1;
			break;
		case "/step-1-2/":
			firstStepFirstTabActive = false;
			firstStepSecondTabActive = true;
			firstStepThirdTabActive = false;
			SecondStepFirstTab = false;
			ThirdStepFirstTab = false;
			FourthStepFirstTab = false;
			currentStep = 2;
			dispatch(changeTimeLeft("około 2 min"));
			break;
		case "/step-1-3/":
			firstStepFirstTabActive = false;
			firstStepSecondTabActive = false;
			firstStepThirdTabActive = true;
			SecondStepFirstTab = false;
			ThirdStepFirstTab = false;
			FourthStepFirstTab = false;
			dispatch(changeTimeLeft("około 90 s"));
			currentStep = 3;
			break;
		case "/step-2/":
			firstStepFirstTabActive = false;
			firstStepSecondTabActive = false;
			firstStepThirdTabActive = false;
			SecondStepFirstTab = true;
			ThirdStepFirstTab = false;
			FourthStepFirstTab = false;
			currentStep = 4;
			dispatch(changeTimeLeft("około 70 s"));
			break;
		case "/step-3/":
			firstStepFirstTabActive = false;
			firstStepSecondTabActive = false;
			firstStepThirdTabActive = false;
			SecondStepFirstTab = false;
			ThirdStepFirstTab = true;
			FourthStepFirstTab = false;
			currentStep = 5;
			dispatch(changeTimeLeft("około 50 s"));
			break;
		case "/step-4/":
			firstStepFirstTabActive = false;
			firstStepSecondTabActive = false;
			firstStepThirdTabActive = false;
			SecondStepFirstTab = false;
			ThirdStepFirstTab = false;
			FourthStepFirstTab = true;
			currentStep = 6;
			dispatch(changeTimeLeft("około 30 s"));
			break;
		default:
			firstStepFirstTabActive = true;
			firstStepSecondTabActive = false;
			firstStepThirdTabActive = false;
			SecondStepFirstTab = false;
			ThirdStepFirstTab = false;
			FourthStepFirstTab = false;
			currentStep = 1;
			break;
	}
	const FirstStepCondition = (firstStepFirstTabActive || firstStepSecondTabActive || firstStepThirdTabActive);
	const NotFirstStepCondition = (!firstStepFirstTabActive && !firstStepSecondTabActive && !firstStepThirdTabActive);
	const ThirdOrFourthStepCondition = (ThirdStepFirstTab || FourthStepFirstTab);
	const currentStepName = FirstStepCondition ? 'Dane pojazdu' : SecondStepFirstTab ? 'Ubezpieczenia' : ThirdStepFirstTab ? 'Historia ubezpieczenia' : 'Właściciel';

	return (
		<ModuleWrapper>
			<LogoContainer>
				<LogoWrapper bgImg={Logo}/>
			</LogoContainer>
			<MobileStepPreviewWrapper>
				<MobileCurrentStepWrapper>
					<MainStepName>{currentStepName}</MainStepName>
					{firstStepFirstTabActive && <SubStepName>marka, model, typ</SubStepName>}
					{firstStepSecondTabActive && <SubStepName>dane techniczne</SubStepName>}
					{firstStepThirdTabActive && <SubStepName>dane zakupu</SubStepName>}
				</MobileCurrentStepWrapper>
				<TabletLogoContainer>
					<TabletLogoWrapper bgImg={Logo}/>
				</TabletLogoContainer>
				<ProgressBarWrapper>
					<ProgressBar>
						<MainStepNumberMobile to={'/'} isLinkActive={firstStepFirstTabFilled} className={FirstStepCondition ? "active" : "filled"}>
							<NumberSpan className={NotFirstStepCondition ? "filled" :  ""}>1</NumberSpan>
							{ NotFirstStepCondition && <TickImg src={TickMobile}/>}
						</MainStepNumberMobile>
						<ProgressLineWrapper>
							<ProgressLine className={!firstStepFirstTabActive ? "done" :  ""}/>
						</ProgressLineWrapper>
						<SmallDotWrapper to={'/step-1-2'} isLinkActive={firstStepFirstTabFilled || firstStepSecondTabFilled}>
							<ProgressSmallDots className={!firstStepFirstTabActive ? "done" :  ""}/>
						</SmallDotWrapper>
						<ProgressLineWrapper>
							<ProgressLine className={(!firstStepFirstTabActive && !firstStepSecondTabActive) ? "done" :  ""}/>
						</ProgressLineWrapper>
						<SmallDotWrapper to={'/step-1-3'} isLinkActive={firstStepSecondTabFilled || firstStepThirdTabFilled}>
							<ProgressSmallDots className={(!firstStepFirstTabActive && !firstStepSecondTabActive) ? "done" :  ""}/>
						</SmallDotWrapper>
						<ProgressLineWrapper>
							<ProgressLine className={NotFirstStepCondition ? "done" :  ""}/>
						</ProgressLineWrapper>
						<MainStepNumberMobile to={'/step-2'} isLinkActive={firstStepThirdTabFilled || secondStepFilled} className={SecondStepFirstTab ? "active" : ThirdOrFourthStepCondition ? "filled" : ""}>
							<NumberSpan className={ThirdOrFourthStepCondition ? "filled" : ""}>2</NumberSpan>
							{ ThirdOrFourthStepCondition && <TickImg src={TickMobile}/>}
						</MainStepNumberMobile>
						<ProgressLineWrapper>
							<ProgressLine className={ThirdOrFourthStepCondition ? "done wider" :  "wider"}/>
						</ProgressLineWrapper>
						<MainStepNumberMobile to={'/step-3'} isLinkActive={secondStepFilled || thirdStepFilled} className={ThirdStepFirstTab ? "active" : (FourthStepFirstTab) ? "filled" : ""}>
							<NumberSpan className={(FourthStepFirstTab) ? "filled" : ""}>3</NumberSpan>
							{(NotFirstStepCondition && !SecondStepFirstTab && !ThirdStepFirstTab ) && <TickImg src={TickMobile}/>}</MainStepNumberMobile>
						<ProgressLineWrapper>
							<ProgressLine className={FourthStepFirstTab ? "done wider" :  "wider"}/>
						</ProgressLineWrapper>
						<MainStepNumberMobile to={'/step-4'} isLinkActive={thirdStepFilled || fourthStepFilled} className={FourthStepFirstTab ? "active" : ""}>
							<NumberSpan className={FourthStepFirstTab ? "active" : ""}>4</NumberSpan>
						</MainStepNumberMobile>
					</ProgressBar>
				</ProgressBarWrapper>
			</MobileStepPreviewWrapper>
			<StepsAndBottomImageWrapper>
				<CloudsAndStepsWrapper>
				<CloudsWrapper>
					<CloudsImage src={Clouds}/>
				</CloudsWrapper>
				<StepsWrapper>
					<MainStep to={'/'} isLinkActive={firstStepFirstTabFilled}>
						<MainStepNumber className={FirstStepCondition ? "active" : "filled"}>
							<NumberSpan className={NotFirstStepCondition ? "filled" :  ""}>1</NumberSpan>
							{ NotFirstStepCondition && <TickImg src={Tick}/>}
						</MainStepNumber>
						<MainStepName className={FirstStepCondition ? "active" : "filled"}>Dane pojazdu</MainStepName>
					</MainStep>
					<SubStepsWrapper>
						<SubStepName to={'/'} isLinkActive={firstStepFirstTabFilled} className={!firstStepFirstTabActive ? "filled" : "active"}>marka, model, typ</SubStepName>
						<SubStepName to={'/step-1-2'} isLinkActive={firstStepFirstTabFilled || firstStepSecondTabFilled} className={!firstStepFirstTabActive && !firstStepSecondTabActive ? "filled" : firstStepSecondTabActive ? "active" : ""}>dane techniczne</SubStepName>
						<SubStepName to={'/step-1-3'} isLinkActive={firstStepSecondTabFilled || firstStepThirdTabFilled} className={NotFirstStepCondition ? "filled" : firstStepThirdTabActive ? "active" : ""}>dane zakupu</SubStepName>
					</SubStepsWrapper>
					<MainStep to={'/step-2'} isLinkActive={firstStepThirdTabFilled || secondStepFilled}>
						<MainStepNumber className={SecondStepFirstTab ? "active" : ThirdOrFourthStepCondition ? "filled" : ""}>
							<NumberSpan className={ThirdOrFourthStepCondition ? "filled" : ""}>2</NumberSpan>
							{ ThirdOrFourthStepCondition && <TickImg src={Tick}/>}
						</MainStepNumber>
						<MainStepName className={(SecondStepFirstTab) ? "active" : (ThirdOrFourthStepCondition) ? "filled" :  ""}>Ubezpieczenia</MainStepName>
					</MainStep>
					<MainStep to={'/step-3'} isLinkActive={secondStepFilled || thirdStepFilled}>
						<MainStepNumber className={ThirdStepFirstTab ? "active" : (FourthStepFirstTab) ? "filled" : ""}>
							<NumberSpan className={(FourthStepFirstTab) ? "filled" : ""}>3</NumberSpan>
							{(NotFirstStepCondition && !SecondStepFirstTab && !ThirdStepFirstTab ) && <TickImg src={Tick}/>}</MainStepNumber>
						<MainStepName className={(ThirdStepFirstTab) ? "active" : (FourthStepFirstTab) ? "filled" :  ""}>Historia ubezpieczenia</MainStepName>
					</MainStep>
					<MainStep to={'/step-4'} isLinkActive={thirdStepFilled || fourthStepFilled}>
						<MainStepNumber className={FourthStepFirstTab ? "active" : ""}>
							<NumberSpan className={FourthStepFirstTab ? "active" : ""}>4</NumberSpan>
						</MainStepNumber>
						<MainStepName className={(FourthStepFirstTab) ? "active" : ""}>Właściciel</MainStepName>
					</MainStep>
				</StepsWrapper>
				</CloudsAndStepsWrapper>
				<BottomImagesWrapper>
					{firstStepFirstTabActive && <SingleAnimationComponent animationJson={FirstStepFirstTabAnimation}/>}
					{firstStepSecondTabActive && <SingleAnimationComponent animationJson={FirstStepSecondTabAnimation}/>}
					{firstStepThirdTabActive && <SingleAnimationComponent animationJson={FirstStepThirdTabAnimation}/>}
					{SecondStepFirstTab && <SingleAnimationComponent animationJson={SecondStepAnimation}/>}
					{ThirdStepFirstTab && <SingleAnimationComponent animationJson={ThirdStepAnimation}/>}
					{FourthStepFirstTab && <SingleAnimationComponent animationJson={FourthStepAnimation}/>}
				</BottomImagesWrapper>
			</StepsAndBottomImageWrapper>
		</ModuleWrapper>
	)
};

export default connect(state => ({
	timeLeft: state.timeLeft.time,
	firstStepFirstTabFilled: state.setPagesAreFilled.firstStepFirstTabFilled,
	firstStepSecondTabFilled: state.setPagesAreFilled.firstStepSecondTabFilled,
	firstStepThirdTabFilled: state.setPagesAreFilled.firstStepThirdTabFilled,
	secondStepFilled: state.setPagesAreFilled.secondStepFilled,
	thirdStepFilled: state.setPagesAreFilled.thirdStepFilled,
	fourthStepFilled: state.setPagesAreFilled.fourthStepFilled,
}), null)(SideStepsPreview);
